import React, { useContext, useEffect, useState } from 'react'

// components
import { SoundCard } from '../components/Cards';

// context
import { AppContext } from '../App';

// styles
import '../styles/Favorites.css';

export default function Favorites() {

  const { favorites } = useContext(AppContext);

  const [currentAudio, setCurrentAudio] = useState(null);
  const [activeContent, setActiveContent] = useState([]);
  const [selectedContentAd, setSelectedContentAd] = useState();
  const [playingElement, setPlayingElement] = useState(null);
  const [showAd, setShowAd] = useState(false);

  const { adCounter, setAdCounter } = useContext(AppContext);

  const handleStopSound = (audio) => {
    if (audio) {
      setCurrentAudio(null)
      setPlayingElement(null);
      audio.pause();
      audio.currentTime = 0;
    }
  };

  useEffect(() => {
    if (currentAudio) {
      currentAudio.play().then(() => setPlayingElement(null))
    }
  }, [currentAudio]);

  return (
    <section className='Favorites'>
      {favorites.length > 0 ?
        favorites.map((favorite, index) => (
          <SoundCard
            key={index}
            index={index}
            imageSrc={favorite.image}
            buttonText={favorite.text}
            sound={favorite.sound}
            currentAudio={currentAudio}
            setCurrentAudio={setCurrentAudio}
            handleStopSound={handleStopSound}
            playingElement={playingElement}
            setPlayingElement={setPlayingElement}
          />
        ))
        :
        <div className='favorites-no-content-image-container'>
          <img src={require('../assets/images/dabicorn_poo_logo_300.png')} alt='No favorites logo' />
          <p>Pas de encore de favoris...</p>
        </div>
      }
    </section>
  )
}
