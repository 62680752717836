import React, { useContext, useEffect, useState } from 'react';

// context
import { AppContext } from '../App';

// icons
import { AiFillStar, AiOutlineStar } from "react-icons/ai";

// packages
import { Blocks } from 'react-loader-spinner';

// styles
import '../styles/Cards.css';

export const SoundCard = ({ buttonText, currentAudio, handleStopSound, imageSrc, index, playingElement, setCurrentAudio, setPlayingElement, sound }) => {

  const { adCounter, incrementAdCounter, favorites, addToFavorites, removeFromFavorites } = useContext(AppContext);

  const isInFavorites = favorites.length > 0 && favorites.some(item => item.text === buttonText);

  const handleClick = () => {
    if (playingElement === buttonText) {
      handleStopSound(currentAudio);
      setPlayingElement(null);
    } else {
      // if (!currentAudio || currentAudio.paused) {
      // setAdCounter(parseInt(adCounter) + 1);
      incrementAdCounter()
      handleStopSound(currentAudio)
      setPlayingElement(buttonText)
      setTimeout(() => {
        setCurrentAudio(new Audio(sound));
      }, 100);
      // }
      // else {
      //   handleStopSound(currentAudio);
      //   setPlayingElement(null);
      // }
    }
  };

  const handleFavoriteClick = (text) => {
    if (isInFavorites) {
      removeFromFavorites(text);
    } else {
      const newFavorite = {
        text: text,
        image: imageSrc,
        sound: sound
      }
      addToFavorites(newFavorite);
    }
  }

  const manageButtonText = (text) => {
    let shortText = text.length > 22 ? text.substring(0, 22) + '...' : text;
    return shortText;
  }

  return (
    <button className='sound-card-container'>
      {imageSrc && sound ?
        <>
          {/* {isPlaying && <div className='sound-card-container-faded' />} */}
          <div className='sound-card-favorite' onClick={() => handleFavoriteClick(buttonText)}>
            {isInFavorites ?
              <AiFillStar className='sound-card-favorite-icon favorite-icon-fill' />
              :
              <AiOutlineStar className='sound-card-favorite-icon favorite-icon-stroke' />
            }
          </div>
          <img src={imageSrc} alt="Button Image" onClick={() => handleClick()} />
          <div className='sound-card-text-container' onClick={() => handleClick()}>
            <div className='sound-card-text'>{manageButtonText(buttonText)}</div>
          </div>
        </>
        :
        <Blocks
          height="80"
          width="80"
          color="#4fa94d"
          ariaLabel="blocks-loading"
          wrapperStyle={{}}
          wrapperClass="blocks-wrapper"
          visible={true}
        />}
    </button>
  );
}