import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';

// components
import { SoundCard } from '../components/Cards';

// context
import { AppContext } from '../App';

// icons
import { IoClose } from "react-icons/io5";

// packages
import { Blocks } from 'react-loader-spinner';

// styles
import '../styles/HeroPage.css';

export default function HeroPage() {
  const { id } = useParams()
  const [currentAudio, setCurrentAudio] = useState(null);
  const [activeContent, setActiveContent] = useState([]);
  const [selectedContentAd, setSelectedContentAd] = useState();
  const [playingElement, setPlayingElement] = useState(null);
  const [showAd, setShowAd] = useState(false);
  const [showShortcutModal, setShowShortcutModal] = useState(false);
  const [data, setData] = useState([])

  const { adCounter, setAdCounter, isShortcut, handleShortcut } = useContext(AppContext);

  const handleStopSound = (audio) => {
    if (audio) {
      setCurrentAudio(null)
      setPlayingElement(null);
      audio.pause();
      audio.currentTime = 0;
    }
  };

  const handleCloseAndHideShortcut = () => {
    setShowShortcutModal(false)
    handleShortcut('refused')
  }

  const getData = async (id) => {
    try {
      const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const query = `https://www.data.dabicorn.konecton.com/hero/id?id=${id}`;
      const response = await fetch(query, options);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const resultData = await response.json();

      if (resultData && resultData.length > 0) {
        resultData.forEach(e => {
          e.image_link = `https://www.data.dabicorn.konecton.com/${e.image_link}`
          e.sound_link = `https://www.data.dabicorn.konecton.com/${e.sound_link}`
        });
        setData(resultData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (currentAudio) {
      currentAudio.play().then(() => setPlayingElement(null))
    }
  }, [currentAudio]);

  const handleAd = () => {
    if (adCounter % 10 === 0 && adCounter !== 0) {
      setShowAd(true);
    } else {
      setShowAd(false);
    }
  }

  useEffect(() => {
    handleAd();
  }, [adCounter])

  useEffect(() => {
    if (id) {
      getData(id)
    }
  }, [id])

  // useEffect(() => {
  //   if (isShortcut === 'empty') {
  //     setShowShortcutModal(true)
  //     const isInWebAppiOS = (window.navigator.standalone === true);
  //     const isInWebAppChrome = (window.matchMedia('(display-mode: standalone)').matches);
  //     if (isInWebAppiOS || isInWebAppChrome) {
  //       handleShortcut('accepted')
  //     }
  //   }
  // }, [])

  const ShortcutModal = () => {
    return (
      <div className='shortcut-modal'>
        <div className='shortcut-modal-content'>
          <button className='shortcut-modal-close' onClick={() => setShowShortcutModal(false)}><IoClose /></button>
          <h2>Raccourcis</h2>
          <p><i>Le saviez-vous :</i></p>
          <p>Vous pouvez ajouter l'app sur l'écran d'accueil de votre appareil</p>
          <button className='shortcut-modal-ok' onClick={() => setShowShortcutModal(false)}>OK</button>
          <button className='shortcut-modal-hide' onClick={() => handleCloseAndHideShortcut()}>Ne plus afficher</button>
        </div>
      </div>
    )
  }

  const AdPage = () => {
    const [showAdCloseButton, setShowAdCloseButton] = useState(false)

    useEffect(() => {
      setTimeout(() => {
        setShowAdCloseButton(true)
      }, 2000)
    }, [])

    return (
      <section className='AdPage'>
        <a href={selectedContentAd.link} target='_blank'>
          <img src={selectedContentAd.image} />
        </a>
        <button className='ad-button-close' onClick={() => setShowAd(false)}>
          <IoClose className={`ad-button-close-icon ${showAdCloseButton && 'ad-button-close-icon-active'}`} />
        </button>
        <div className='ad-overlay' />
      </section>
    )
  }

  return (
    <section className='HeroPage'>
      {/* {showAd && <AdPage />} */}
      {showShortcutModal && <ShortcutModal />}
      {data.length > 0 ? data.map((content, index) => (
        <SoundCard
          key={index}
          index={index}
          imageSrc={content.image_link}
          buttonText={content.sound_name}
          sound={content.sound_link}
          currentAudio={currentAudio}
          setCurrentAudio={setCurrentAudio}
          handleStopSound={handleStopSound}
          playingElement={playingElement}
          setPlayingElement={setPlayingElement}
        />
      ))
        :
        <Blocks
          height="80"
          width="80"
          color="#4fa94d"
          ariaLabel="blocks-loading"
          wrapperStyle={{}}
          wrapperClass="blocks-wrapper"
          visible={true}
        />}
      {/* <div className='ad-banner-container'>
        <a href='#' target='_blank'><img src={require('../assets/images/mobile-ad-banner-1.png')} alt="Ad Banner" /></a>
      </div> */}
    </section>
  )
}
