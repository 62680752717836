import { createContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

// screens
import Favorites from './screens/Favorites';
import HeroPage from './screens/HeroPage'
import Home from './screens/Home';
import Navbar from './components/Navbar';

// styles
import './App.css';

export const AppContext = createContext();

function App() {
  const [activeContentTitle, setActiveContentTitle] = useState('Vos Favoris');
  const [activeContentWebsiteLink, setActiveContentWebsiteLink] = useState('');
  const [activeContentId, setActiveContentId] = useState(null);
  const [displayNavbar, setDisplayNavbar] = useState(false);
  const [adCounter, setAdCounter] = useState(0);
  const [favorites, setFavorites] = useState(() => {
    const localData = localStorage.getItem('favorites');
    return localData ? JSON.parse(localData) : [];
  });
  const [isShortcut, setIsShortcut] = useState(() => {
    const localData = localStorage.getItem('isShortcut');
    return localData ? JSON.parse(localData) : 'empty';
  });

  const [heroesData, setHeroesData] = useState([]);

  useEffect(() => {
    localStorage.setItem('favorites', JSON.stringify(favorites));
  }, [favorites]);

  const incrementAdCounter = () => {
    setAdCounter(prevCounter => prevCounter + 1);
  };

  const addToFavorites = (item) => {
    setFavorites(prevFavorites => [...prevFavorites, item]);
  };

  const removeFromFavorites = (text) => {
    setFavorites(prevFavorites =>
      prevFavorites.filter(favorite => favorite.text !== text)
    );
  };

  const clearFavorites = () => {
    setFavorites([]);
  };

  const handleShortcut = (choice) => {
    localStorage.setItem('isShortcut', JSON.stringify(choice));
    setIsShortcut(choice);
  };

  const values = {
    adCounter,
    incrementAdCounter,
    favorites,
    addToFavorites,
    removeFromFavorites,
    clearFavorites,
    isShortcut,
    handleShortcut,
    setActiveContentWebsiteLink,
    activeContentWebsiteLink
  };

  const getHeroes = async () => {
    try {
      const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const query = `https://www.data.dabicorn.konecton.com/hero/`;
      const response = await fetch(query, options);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (data && data.length > 0) {
        setHeroesData(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getHeroes();
  }, []);

  return (
    <AppContext.Provider value={values}>
      <Router>
        <header className="App-header">
          <Navbar
            activeContentTitle={activeContentTitle}
            setActiveContentTitle={setActiveContentTitle}
            setActiveContentId={setActiveContentId}
            setDisplayNavbar={setDisplayNavbar}
            displayNavbar={displayNavbar}
            heroesList={heroesData}
          />
        </header>
        <Routes>
          {/* <Route path="/" element={<Home />} /> */}
          <Route path="/" element={<Favorites />} />
          <Route path="/hero-page/:id" element={<HeroPage />} />
          <Route path="/favorites" element={<Favorites />} />
        </Routes>
      </Router>
    </AppContext.Provider>
  );
}

export default App;
