import React, { useContext, useEffect, useState } from 'react'

// context
import { AppContext } from '../App';

// icons
import { AiFillStar } from "react-icons/ai";
import { HiOutlineMenu } from "react-icons/hi";
import { IoClose } from "react-icons/io5";

// packages
import { Blocks } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';

// styles
import '../styles/Navbar.css'

function Navbar({ displayNavbar, setDisplayNavbar, activeContentTitle, setActiveContentTitle, setActiveContentId, heroesList }) {
  const [activeContentLink, setActiveContentLink] = useState('');
  const [logoAnimated, setLogoAnimated] = useState(false);
  const { clearFavorites, setActiveContentWebsiteLink, activeContentWebsiteLink } = useContext(AppContext);
  const navigate = useNavigate();

  const handleClick = (id, title, link) => {
    if (link) {
      setActiveContentWebsiteLink(link)
    } else {
      setActiveContentWebsiteLink(null)

    }
    setActiveContentTitle(title);
    setDisplayNavbar(false);
    if (id !== 0) {
      navigate(`/hero-page/${id}`)
    } else {
      navigate(`/favorites`)

    }
  }

  const handleAnimatedLogo = () => {
    setLogoAnimated(true);
    setTimeout(() => {
      setLogoAnimated(false);
    }, 1100);
  }

  // // console.log('heroesList Navbar : ', heroesList)
  // useEffect(() => {
  //   const currentActiveContent = heroesList.find(content => content.name === activeContentTitle);
  //   // const currentActiveContent = contentsList.find(content => content.contentName === activeContentTitle);
  //   if (currentActiveContent) {
  //     // setActiveContentLink(currentActiveContent.contentLink);
  //     setActiveContentLink(currentActiveContent.image_link);
  //   }

  // }, [activeContentTitle]);

  useEffect(() => {
    setActiveContentTitle(activeContentTitle);
  }, [activeContentTitle]);

  return (
    <nav className='Navbar'>
      <HiOutlineMenu className='navbar-controller-open' onClick={() => setDisplayNavbar(true)} />
      <a href={activeContentWebsiteLink} target='_blank'><h1>{activeContentTitle}</h1></a>
      {displayNavbar &&
        <div className={`navbar-open-container`}>
          <IoClose className='navbar-controller-close' onClick={() => setDisplayNavbar(false)} />
          <div className='navbar-open-content'>
            <div className='navbar-open-button-container navbar-open-logo-container' onClick={() => handleAnimatedLogo()}>
              <img className={logoAnimated && 'navbar-open-logo-animated'} src={require('../assets/images/logo_licorn_192.png')} alt="Dabicorn" />
              <p className='navbar-open-logo-text'>Dabicorn</p>
            </div>
            <div className='navbar-open-button-container' onClick={() => handleClick(0, 'Vos Favoris')} >
              <AiFillStar className='navbar-open-button-favorites-icon' />
              <p className='navbar-open-button-favorites-text'>Vos Favoris</p>
            </div>
            {/* {contentsList.length > 0 ?
              contentsList.map((content, index) => (
                <div className='navbar-open-button-container' key={index} onClick={() => handleClick(content.contentName)} >
                  <img src={content.contentImage} alt="Button Image" />
                  <p className='navbar-open-button-text'>{content.contentName}</p>
                </div>
              )) */}
            {heroesList.length > 0 ?
              heroesList.map((content, index) => (
                <div className='navbar-open-button-container' key={index} onClick={() => handleClick(content.id, content.name, content.website_link)} >
                  <img src={content.image_link} alt="Button Image" />
                  <p className='navbar-open-button-text'>{content.name}</p>
                </div>
              ))
              // heroesList.map((content, index) => (
              //   <a className='navbar-open-button-container' href={`/hero-page/${content.id}`} key={index} >
              //     <img src={content.image_link} alt="Button Image" />
              //     <p className='navbar-open-button-text'>{content.name}</p>
              //   </a>
              // ))
              :
              <Blocks
                height="80"
                width="80"
                color="#4fa94d"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                visible={true}
              />}
          </div>
          {/* <button onClick={() => clearFavorites()}>CLEAR</button> */}
        </div>
      }
    </nav>
  )
}

export default Navbar